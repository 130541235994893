import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-awesome-styled-grid"
import { Heading2 } from "../../components/Heading"
import Layout from "../../components/Layout"
import Block from "../../components/Block"
import { Hero, HeroContent, HeroHeading } from "../../components/Hero"
import BackgroundImage from "gatsby-background-image"
import { StaticImage } from "gatsby-plugin-image"
import { TrackedLink } from "../../components/Button"
import styled from "styled-components"
import DivHelper from "../../components/DivHelper"
import { TableOfContents } from "../../components/TableOfContents"

const ProductBox = styled(DivHelper)`
  display: inline-block;
  margin-top: 20px;
  border-radius: 4px;
  border: 6px solid ${props => props.color};
  padding: 10px 20px;
  text-decoration: underline;
  max-width: 250px;
`

const ProductLabel = styled.p`
  text-align: center;
  font-weight: 600;
`

const Background = ({ image, className, children }) => {
  return (
    <BackgroundImage
      className={className}
      fluid={image.childImageSharp.fluid}
      style={{ height: "100%" }}
    >
      {children}
    </BackgroundImage>
  )
}

const CbdGleitgel = ({ location, data: { image } }) => {
  return (
    <Layout
      location={location}
      title="CBD Gleitgel: Das Upgrade für Ihr Liebesleben"
      mt0
      light
    >
      <Hero heightVh={50}>
        <Background image={image}>
          <HeroContent align="flex-end">
            <Container>
              <Row>
                <Col>
                  <HeroHeading>
                    CBD Gleitgel: Das Upgrade für Ihr Liebesleben
                  </HeroHeading>
                </Col>
              </Row>
            </Container>
          </HeroContent>
        </Background>
      </Hero>
      <Container>
        <Block>
          <Row>
            <Col>
              <Heading2>Übersicht</Heading2>
              <TableOfContents>
                <li>
                  <a href="#einleitung">
                    Erst CBD Öle und CBD Fruchtgummis und jetzt CBD Gleitgele in
                    Deutschland
                  </a>
                </li>
                <li>
                  <a href="#wirkweise">
                    Medizinische Wirkweise von CBD Gleitgelen auf Vulva und Anus
                  </a>
                </li>
                <li>
                  <a href="#erfahrungen">
                    CBD Gleitgel Erfahrungen: Wofür bzw. was bringt das neue
                    Wundermittel?
                  </a>
                </li>
                <li>
                  <a href="#unterschiede">
                    Unterschiede von CBD und Cannabis Gleitmitteln
                  </a>
                </li>
                <li>
                  <a href="#hinweise">
                    Hinweise vor dem ersten Test mit CBD Gleitgel
                  </a>
                </li>
                <li>
                  <a href="#empfehlung">Empfehlung CBD Öl statt CBD Gleitgel</a>
                </li>
              </TableOfContents>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="einleitung">
                Erst CBD Öle und CBD Fruchtgummis und jetzt CBD Gleitgele in
                Deutschland
              </Heading2>
              <p>
                Neben den{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl Testsiegern</Link> und
                den{" "}
                <Link to="/vergleiche/cbd-fruchtgummis/">CBD Fruchtgummis</Link>{" "}
                wie die bekannten Cannabis Gummibärchen von Sarah's Blessing
                regt sich etwas Neues auf dem CBD Markt. Die Rede ist von auf Öl
                oder Gel basierten Gleitmitteln, welche mit dem Wirkstoff CBD
                (Cannabidiol) angereichert sind. Es ist etwas Spannendes und
                Aufregendes für das Liebesleben, denn immer mehr und mehr{" "}
                <strong>
                  Menschen kaufen CBD Gleitgele, testen es und sammeln ihre
                  Erfahrungen
                </strong>{" "}
                darin. Hier geht es zum{" "}
                <Link to="/vergleiche/cbd-gleitgel/">CBD Gleitgel Test</Link>.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col md={8} xl={9}>
              <Heading2 id="wirkweise">
                Medizinische Wirkweise von CBD Gleitgelen auf Vulva und Anus
              </Heading2>
              <p>
                Wegen ihrer Fähigkeit die Sensibilität zu steigern,
                Verspannungen zu lösen und die sexuelle Erregung zu fördern,
                werden sie folglich immer beliebter. Es gibt die ersten CBD
                Gleitgele im Test. Durch die Kombination von Gleitgel und CBD
                soll das{" "}
                <strong>
                  Sexerlebnis auf ein ganz neues Level der Erregung befördert
                  werden
                </strong>{" "}
                und verspricht neue Erlebnisse. Ganz besonders für Frauen, die
                nur schwer feucht werden oder Schwierigkeiten haben, zum
                Höhepunkt zu gelangen, soll es wie ein Wundermittel wirken.
                Durch die dünne (Schleim-)Haut der Geschlechtsorgane oder des
                Anus beim Analsex kann der Wirkstoff besonders gut und schnell
                penetrieren, sodass deutliche{" "}
                <strong>Wirkungen schon nach kurzer Zeit spürbar</strong> sind.
                Aber Achtung: Nicht jedes CBD Gleitgel ist gleich. Es gibt
                starke Unterschiede im CBD Gehalt. Daher lohnt sich ein{" "}
                <Link to="/vergleiche/cbd-gleitgel/">Vergleich</Link>.
              </p>
              <p>
                Besonders Frauen profitieren von der Anwendung des CBD
                Gleitmittels, da die Schleimhäute der Vagina und die Vulva sehr
                stark durchblutet sind und{" "}
                <strong>viele Cannabinoid-Rezeptoren</strong> besitzen.
              </p>
              <p>
                Endocannabinoide sind Signalstoffe, welche der Körper selbst
                produziert. Diese binden an bestimmte Rezeptoren im Organismus
                und unterstützen somit unter anderem die Stressbewältigung und
                regulieren Entzündungsreaktionen sowie das Schmerzempfinden. Da
                die erwähnten Rezeptoren auch{" "}
                <strong>durch das Cannabidiol des Gleitgels aktiviert</strong>{" "}
                werden, profitieren Anwender*innen durch den Kauf von CBD
                Gleitmitteln von positiven Wirkungen, die im nachfolgenden
                Absatz beschrieben werden.
              </p>
            </Col>
            <Col md={4} xl={3} align="center">
              <TrackedLink
                href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                category="ratgeber"
                action="gleitgel"
                label="hanfgefluester-ad"
              >
                <ProductBox color="#ff8573" center>
                  <StaticImage
                    src="../../assets/images/products/cbd-gleitgel/joy.png"
                    alt="Naturecan"
                    layout="constrained"
                    placeholder="blurred"
                    width={200}
                  />
                  <ProductLabel>
                    Zum CBD Gleitgel Preis-Leistungs-Sieger
                  </ProductLabel>
                </ProductBox>
                <img
                  src="https://t.adcell.com/p/view?promoId=297834&slotId=98699"
                  border="0"
                  width="1"
                  height="1"
                  alt=""
                />
              </TrackedLink>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row align="center" justify="center">
            <Col xs={12} lg={7}>
              <Heading2 id="erfahrungen">
                CBD Gleitgel Erfahrungen: Wofür bzw. was bringt das neue
                Wundermittel?
              </Heading2>
              <h3>1. Die sexuelle Erregung steigt</h3>
              <p>
                Besonders bei Frauen haben Studien einen eindeutigen{" "}
                <strong>
                  Zusammenhang zwischen den Endocannabinoid-Werten und der
                  Erregung
                </strong>{" "}
                festgestellt (
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/22462722/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  vgl. Klein et al. 2012
                </a>
                ). Folglich kann man durch ein Gleitmittel, welches CBD enthält,
                schneller und besser &bdquo;in Stimmung&ldquo; kommen.
              </p>
              <h3>2. Erweiterung der Gefäße</h3>
              <p>
                In sämtlichen Versuchen konnte gezeigt werden, dass Cannabidiol
                den <strong>Blutfluss in den Bereichen fördern</strong> kann, in
                denen es angewendet wird (
                <a
                  href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3579247/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  vgl. Stanley et al. 2013
                </a>
                ) – CBD Gleitgel kann also einen gefäßerweiternden Effekt
                erzeugen. Dies führt zu:
              </p>
              <h3>3. Verbessertes Gefühlserleben</h3>
              <p>
                Genau diese Erweiterung der Gefäße und der dadurch resultierende
                gesteigerte Blutfluss tragen dazu bei, dass in den Bereichen, in
                denen das CBD aufgetragen wird, die{" "}
                <strong>Sensibilität und Empfindsamkeit steigen</strong> - auf
                eine sehr angenehme Art und Weise.
              </p>
              <h3>4. Entspannung</h3>
              <p>
                Dass besonders die Psyche und das Wohlbefinden beim Sex eine
                entscheidende Rolle spielen können, weiß womöglich Jeder.
                Körperliche als auch geistige Entspannung werden durch
                Cannabidiol aufgrund seiner{" "}
                <strong>
                  stressreduzierenden, schmerzlindernden und entspannenden
                  Wirkungen
                </strong>{" "}
                gefördert (
                <a
                  href="http://www.wbc.poznan.pl/Content/501802/8_Skopek_TSS_2021_282_147-151.pdf"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  vgl. Škopek et al. 2021
                </a>
                ). So gelingt das &bdquo;Fallenlassen&ldquo; gleich viel besser.
              </p>
            </Col>
            <Col xs={12} sm={10} md={6} lg={5}>
              <StaticImage
                src="../../assets/images/stock/avocado.jpg"
                placeholder="blurred"
                layout="fullWidth"
                alt="Aufgeschnittene Avocado"
              />
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="unterschiede">
                Unterschiede von CBD und Cannabis Gleitmitteln
              </Heading2>
              <p>
                Einige von Euch werden unter der vorherigen Aufzählung die
                starke psychoaktive, euphorisierende Wirkung vermissen.
                &bdquo;High&ldquo; wird man allerdings nicht durch CBD, sondern
                durch einen anderen in Hanf enthaltenen Wirkstoff - dem THC
                (Tetrahydrocannabinol). Dieser ist in Deutschland allerdings
                nicht ganz legal und kommt daher nicht oder in nur sehr geringen
                Mengen in CBD Gleitgelen vor. Alle Produkte im{" "}
                <Link to="/vergleiche/cbd-gleitgel/">CBD Gleitgel Test</Link>{" "}
                sowie die{" "}
                <TrackedLink
                  href="https://t.adcell.com/p/click?promoId=297834&slotId=98699&param0=https%3A%2F%2Fhanfgefluester.de%2Fprodukt%2Fjoy-cbd-gleitgel%2F"
                  category="ratgeber"
                  action="gleitgel"
                  label="hanfgefluester-text"
                >
                  CBD Gleitgel Testsieger
                </TrackedLink>{" "}
                enthalten CBD mit oder ohne einem{" "}
                <strong>THC Gehalt von weniger als 0,2%</strong> und sind somit
                legal in Deutschland zu kaufen. Einen ausführlichen Blogbeitrag
                zur Legalität erfahren Sie in unserem Ratgeber{" "}
                <Link to="/ratgeber/cbd-legalitaet/">CBD kaufen ist legal</Link>{" "}
                – das sagen EuGH und BGH dazu. Im Detail wird im Folgenden auf
                die Unterscheidung zwischen Cannabis Gleitmitteln und CBD
                Gleitmitteln eingegangen:
              </p>
              <ul>
                <li>
                  <u>Cannabis Gleitmittel</u> enthalten üblicherweise eine
                  Mischung aus THC, CBD, Terpenen und anderen in Cannabis
                  vorkommenden Verbindungen.
                </li>
                <li>
                  <u>CBD Gleitmittel</u> hingegen enthalten typischerweise kein
                  THC und führen folglich auch nicht zu den von THC bekannten
                  Nebenwirkungen.
                </li>
              </ul>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row>
            <Col>
              <Heading2 id="hinweise">
                Hinweise vor dem ersten Test mit CBD Gleitgel
              </Heading2>
              <p>
                Durch die lokale Applikation des CBD-basierten Gleitmittels
                sowie der Abwesenheit von THC sind Nebenwirkungen und
                Überdosierungen sehr unwahrscheinlich, was CBD Gleitmittel recht
                sicher in ihrer Anwendung macht. Zu beachten ist allerdings
                Folgendes:
              </p>
              <ul>
                <li>
                  Öl-basierte Gleitmittel sollten{" "}
                  <strong>niemals zusammen mit Latex-Kondomen</strong> verwendet
                  werden.
                </li>
                <li>
                  Für Menschen mit einer <strong>Cannabis-Allergie</strong> sind
                  CBD Produkte nicht geeignet.
                </li>
                <li>
                  Vor dem Benutzen sind die{" "}
                  <strong>Anwendungshinweise des Herstellers</strong> zu
                  beachten.
                </li>
              </ul>
              <p>
                Ansonsten kann CBD unbedenklich angewandt werden, denn es
                besteht laut WHO keine Gefahr der Abhängigkeit oder des
                Missbrauchs (
                <a
                  href="https://www.who.int/news/item/13-09-2018-40th-ecdd-news-briefing"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  vgl. WHO 2018
                </a>
                ). Einsteiger beginnen am besten mit geringen Dosen, um den
                Körper an die Wirkung des Cannabidiols zu gewöhnen.
              </p>
            </Col>
          </Row>
        </Block>
        <Block>
          <Row align="center">
            <Col md={8} xl={9}>
              <Heading2 id="empfehlung">
                Empfehlung CBD Öl statt CBD Gleitgel
              </Heading2>
              <p>
                Wer sich noch nicht an CBD Gleitgele im Intimbereich traut oder
                der Partner es nicht möchte, aber dennoch die positiven
                Wirkungen von CBD erleben möchte, kann es{" "}
                <strong>zunächst einmal mit CBD Ölen probieren</strong>.
                Insbesondere das 10% CBD Öl eignet sich gut für Anfänger. Es
                gibt viele CBD Öl Anbieter, wobei{" "}
                <Link to="/vergleiche/cbd-oel/">hier</Link> die wichtigsten und
                bekanntesten CBD Öle mit den jeweiligen Erfahrungen und Preisen
                beschrieben werden. Auch Einnahmeempfehlungen und weitere
                wissenswerte Informationen zu CBD Ölen werden in dem Beitrag
                beschrieben. Die{" "}
                <Link to="/vergleiche/cbd-oel/">CBD Öl Testsieger</Link>{" "}
                empfehlen sich für den ersten Kauf, um Fehlentscheidungen und
                schlechte Erfahrungen mit CBD Öl zu vermeiden.
              </p>
            </Col>
            <Col md={4} xl={3} align="center">
              <TrackedLink
                href="https://t.adcell.com/p/click?promoId=270410&slotId=98699&param0=https%3A%2F%2Fwww.naturecan.de%2Fproducts%2F10-cbd-oel"
                category="ratgeber"
                action="gleitgel"
                label="naturecan-oel"
              >
                <ProductBox color="#7c9f8c" center>
                  <StaticImage
                    src="../../assets/images/products/cbd-oel/naturecan.png"
                    alt="Naturecan"
                    layout="constrained"
                    placeholder="blurred"
                    width={200}
                  />
                  <ProductLabel>CBD Öl 10% von Naturecan</ProductLabel>
                </ProductBox>
              </TrackedLink>
              <img
                src="https://t.adcell.com/p/view?promoId=270410&slotId=98699"
                border="0"
                width="1"
                height="1"
                alt=""
              />
            </Col>
          </Row>
        </Block>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    image: file(relativePath: { eq: "stock/oil-water.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CbdGleitgel
